
import { mapActions, mapGetters, mapMutations } from "vuex";
import filters from "~/mixins/filters";
import cardClick from "~/mixins/cardClick";

export default {
  mixins: [filters, cardClick],
  computed: {
    ...mapGetters({
      likesArray: "favorite/favorite/likesArray",
      currentCar: "modal/modal-choose/currentCar",
      settings: "settings/settings/settings"
    }),
  },
  props: {
    isForm: {
      type: Boolean,
      default: false
    },
    long: {
      type: Boolean,
      default: false
    },
    offer: {
      type: Object,
      default: () => {}
    },
    url: {
      type: String,
      default: ""
    },
    choose: {
      type: Boolean,
      default: false
    },
    isNewCar:{
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions({
      liked: "favorite/favorite/liked",
      openModal: "modal/modal-main/openModal",
      closeModal: "modal/modal-main/closeModal"
    }),
    ...mapMutations({
      setCurrentCar: "modal/modal-choose/SET_CURRENT_CAR"
    }),
    async like() {
      await this.liked(this.offer.external_id);
    },
    async call() {
      let payload = {
        modal_data: this.offer,
        modal_component: "modal-callback",
        modal_title: "Обратный звонок",
        modal_sub_title: this.offer.name
      };
      await this.openModal(payload);
    },
    async autoteka(carInfo) {
      let payload = {
        modal_data: carInfo,
        modal_component: "modal-autoteka",
        modal_title: "Отчет «Автотеки» от " + carInfo.createdAt,
        modal_sub_title: carInfo.name
      };
      this.openModal(payload);
    },
    async credit() {
      let payload = {
        modal_data: this.offer,
        modal_component: "modal-credit",
        modal_title: "Заявка на кредит",
      };
      await this.openModal(payload);
    }
  }
};
