import { render, staticRenderFns } from "./catalog-item-price.vue?vue&type=template&id=2f8ea1ba&"
import script from "./catalog-item-price.vue?vue&type=script&lang=js&"
export * from "./catalog-item-price.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TippyQuestion: require('/home/d/didisher2/si-auto.ru/www/components/tippy/tippy-question.vue').default})
